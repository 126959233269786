<!--
 * @Author: zhouxingtong
 * @Email: 495144492@qq.com
 * @Date: 2023-04-17 18:15:27
 * @LastEditors: zhouxingtong
 * @LastEditTime: 2024-12-10 14:40:55
 * @Description: 
-->
<template>
	<div class="content-wrap" ref="container">
		<a-row type="flex" justify="start">
			<a-col :span="3">
				<div class="side-menu">
					<a-menu :selectedKeys="activeMenu" mode="inline">
						<a-menu-item :key="item.name" v-for="item in menuList">
							<router-link :to="{name: item.name}">{{ item.title }}</router-link>
						</a-menu-item>
					</a-menu>
				</div>
			</a-col>
			<a-col :span="21">
				<div class="main" id="main">
					<!-- <breadcrumb /> -->
					<router-view />
				</div>
			</a-col>
		</a-row>
	</div>
</template>
<script>
import Breadcrumb from '@/layouts/Breadcrumb'
export default {
	name: 'Personalization',
	components: {Breadcrumb},
	data() {
		return {
			menuList: [
				{title: '功能开关', name: 'OptionsSwitch'},
				{title: '个性化设置', name: 'PersonalSetting'},
				{title: '餐段设置', name: 'MealSetting'},
				{title: '二轮设置', name: 'RoundSetting'},
				{title: '绩效设置', name: 'ServicePerformance'}
			]
		}
	},
	created() {},
	methods: {},
	computed: {
		activeMenu() {
			return [this.$route.name]
		}
	}
}
</script>
<style scoped lang="less">
.ant-menu-inline {
	border-right: 0;
}

.content-wrap {
	background-color: #f0f2f5;
	min-height: calc(100vh - 66px);
	height: 100%;
}

.side-menu {
	box-sizing: border-box;
	padding-top: 25px;
	height: calc(100%);
	background-color: #fff;
}

.main {
	min-height: calc(100vh - 66px);
	overflow: hidden;
	padding: 20px;
}
</style>
